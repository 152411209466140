import { auth0 } from "@/config/auth0";
import { OpenAPI } from "@/open-api-code/ili-api";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import jwt_decode from "jwt-decode";
import { getGroupAvailableServices } from "@/layouts/MainLayout/client.api";
import {
  INLINE_INSPECTIONS,
  PIPESCANNER_INSPECTIONS,
} from "@/features/administration/constants";
import { usePipelinesStore } from "./usePipelinesStore";
import { useToast } from "vue-toast-notification";
import { useScannerControlStore } from "./useScannerControlStore";
import { userCommentsAPI } from "@/features/comments/api";
import { useMapStore } from "./useMapStore";

const toast = useToast();

const requests = new Map();

export const useAuthStore = defineStore("auth", () => {
  const scannerControlStore = useScannerControlStore();
  const pipelinesStore = usePipelinesStore();
  const mapStore = useMapStore();

  const userPermissions = ref([]);

  const isAdmin = computed(() =>
    userPermissions.value.includes("client:admin")
  );

  function setUserPermissions(permissions) {
    userPermissions.value = permissions;
  }

  const serviceGroups = ref([]);

  function setServiceGroups(services) {
    serviceGroups.value = services;
  }

  const selectedGroup = ref("");
  const isLoadingGroupData = ref(false);

  function setSelectedGroup(group) {
    selectedGroup.value = group;
  }

  function setUserGroups(groups) {
    setServiceGroups(groups);
    setSelectedGroup(groups[0]);
  }
  async function getGroupData() {
    try {
      isLoadingGroupData.value = true;

      if (requests.has("availableServicesRequest")) {
        requests.get("availableServicesRequest").abort();
      }
      if (requests.has("pipelinesRequest")) {
        requests.get("pipelinesRequest").cancel();
      }
      if (requests.has("scannersRequest")) {
        requests.get("scannersRequest").abort();
      }

      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();
      const services = await getGroupAvailableServices(
        token,
        selectedGroup.value,
        controller
      );
      const pipesAccess = services.some(
        ({ service }) => service === INLINE_INSPECTIONS
      );

      requests.set("availableServicesRequest", controller);

      if (pipesAccess) {
        pipelinesStore.getPipelines();
      } else {
        pipelinesStore.setIsLoadingPipelines(false);
      }

      const scannersServiceToSet = services.find(
        ({ service }) => service === PIPESCANNER_INSPECTIONS
      );

      scannerControlStore.setScannersService(scannersServiceToSet);
      if (scannersServiceToSet) {
        scannerControlStore.setExportSettings(
          scannersServiceToSet.exportSettings
        );

        if (mapStore.showMapScanners)
          await scannerControlStore.fetchPipeScanners();
      }
    } catch (error) {
      if (error.name !== "CanceledError") {
        toast.error(`Read services - ${error.message}`, {
          position: "top-right",
        });
        pipelinesStore.setIsLoadingPipelines(false);
      }
    } finally {
      isLoadingGroupData.value = false;
    }
  }
  async function fetchPermissionsAndGroups() {
    const token = await auth0.getAccessTokenSilently();
    if (token) {
      OpenAPI.TOKEN = token;
      const payload = await jwt_decode(token);
      setUserPermissions(payload.permissions);
      const groupKey = "https://acqgroups.cloud/groups";
      if (Object.prototype.hasOwnProperty.call(payload, groupKey)) {
        setUserGroups(payload[groupKey]);
        await getGroupData();
      }
    } else {
      console.error("No token, try to log in!");
    }
  }

  watch(selectedGroup, () => {
    pipelinesStore.cancelRequests();
    pipelinesStore.setIsLoadingStats(false);
    userCommentsAPI.abortAll();
  });

  return {
    userPermissions,
    isAdmin,
    serviceGroups,
    selectedGroup,
    setUserPermissions,
    setServiceGroups,
    setSelectedGroup,
    setUserGroups,
    fetchPermissionsAndGroups,
    isLoadingGroupData,
    getGroupData,
  };
});
