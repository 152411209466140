<template>
  <ScannerControlMapmode v-if="mapStore.isMapMode" />
  <ScannerControl v-else />
</template>

<script>
import { ScannerControl, ScannerControlMapmode } from "./views";
import {
  useAuthStore,
  useMapStore,
  usePipelinesStore,
  usePipeScannersStore,
  useScannerControlStore,
} from "@/store";

export default {
  name: "ScannerControlPage",

  components: {
    ScannerControl,
    ScannerControlMapmode,
  },

  setup() {
    return {
      mapStore: useMapStore(),
      authStore: useAuthStore(),
      pipelinesStore: usePipelinesStore(),
      pipeScannersStore: usePipeScannersStore(),
      scannerControlStore: useScannerControlStore(),
    };
  },

  watch: {
    "authStore.selectedGroup": {
      handler() {
        this.pipeScannersStore.resetScanners();
        this.pipelinesStore.setSelectedPipelineId(null);
        this.scannerControlStore.fetchPipeScanners();
      },
      immediate: true,
    },
  },

  beforeUnmount() {
    this.pipeScannersStore.cancelAllRequests();
    this.scannerControlStore.cancelAllRequests();
  },
};
</script>
