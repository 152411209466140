<template>
  <Dashboard v-if="!canShowPipePartsPage" />
  <PipePartsMapmode
    v-else-if="mapStore.isMapMode"
    :pipePartsMapData="pipePartsMapData"
    :typeOptions="typeOptions"
    :materialOptions="materialOptions"
    :gradientOptions="gradientOptions"
    :gradientColor="gradientColor"
    :histogramTitle="histogramTitle"
  />
  <PipeParts
    v-else
    :typeOptions="typeOptions"
    :materialOptions="materialOptions"
    :gradientOptions="gradientOptions"
    :gradientColor="gradientColor"
    :pipePartsMapData="pipePartsMapData"
    :histogramTitle="histogramTitle"
  />
</template>

<script>
import { computed } from "vue";

import { PipeParts, PipePartsMapmode } from "./views";

import { getPipePartsOptions } from "@/pages/pipe-parts/configs";
import { gradientColorArr } from "@/colorLegends.js";
import { getAnomaliesMapData } from "@/features/anomaly/helpers";
import { getPipeMapData } from "@/pages/dashboard/helpers/getPipeMapData";
import Dashboard from "@/pages/dashboard/DashboardPage.vue";
import {
  getPipeSegmentsMapData,
  getSelectedSegmentsMapData,
  getMinMaxWallThickness,
} from "@/pages/pipe-parts/helpers";
import { getMinMaxValue } from "@/utils/filterHelpers.js";
import {
  useAnomaliesStore,
  useGlobalStore,
  useMapStore,
  usePipelinesStore,
  usePipePartsStore,
} from "@/store";

export default {
  name: "PipePartsPage",

  components: {
    Dashboard,
    PipeParts,
    PipePartsMapmode,
  },

  setup() {
    return {
      getMinMaxWallThickness,
      mapStore: useMapStore(),
      pipelinesStore: usePipelinesStore(),
      anomaliesStore: useAnomaliesStore(),
      pipePartsStore: usePipePartsStore(),
      globalStore: useGlobalStore(),
    };
  },

  provide() {
    return {
      isAwaitingDigup: computed(() => this.pipePartsStore.isAwaitingDigup),
      onGenerateDigUp: this.getDigupSheet, // ContextMenu
      cancelDigUp: () => {
        this.pipePartsStore.partsSelector.clear();
      }, // ContextMenu
    };
  },

  beforeUnmount() {
    this.pipePartsStore.cancelAllRequests();
  },

  computed: {
    canShowPipePartsPage() {
      return this.pipelinesStore.selectedInspectionId;
    },
    pipeParts() {
      return this.pipePartsStore.pipePartsWithTranslatedQualityAnomaly;
    },

    shouldRedirectToDashboard() {
      return !this.pipelinesStore.selectedInspectionId;
    },

    histogramTitle() {
      return this.gradientOptions.find(
        (opt) => opt.value === this.pipePartsStore.selectedGradientMode
      )?.title;
    },

    typeOptions() {
      return this.pipePartsStore.pipePartTypes.map((type) => ({
        value: type,
        title: this.$t(`pipe_parts.types.${type}`),
      }));
    },

    materialOptions() {
      return this.pipePartsStore.materials.map((material) => ({
        value: material,
        title: this.$t(`materials.${material}`),
      }));
    },

    pipePartsMapData() {
      const mapData = [];

      const pipeMapData = getPipeMapData(
        this.pipelinesStore.selectedInspectionStats
      );
      const pipeSegmentsMapData = getPipeSegmentsMapData(
        this.pipePartsStore.filteredPipeParts,
        this.pipePartsStore.partsSelector,
        this.pipePartsStore.selectedGradientMode,
        this.pipePartsStore.selectedLegend,
        this.gradientColor,
        this.$t
      );
      const selectedSegmentsMapData = getSelectedSegmentsMapData(
        this.pipeParts,
        this.pipePartsStore.partsSelector.value
      );

      mapData.push(pipeMapData, pipeSegmentsMapData, selectedSegmentsMapData);

      if (this.pipePartsStore.showAnomalies) {
        const { largeAnomaliesMapData, smallAnomaliesMapData } =
          getAnomaliesMapData(
            this.anomaliesStore.anomalies,
            this.anomaliesStore.anomaliesTypes,
            null,
            true
          );
        mapData.push(largeAnomaliesMapData, smallAnomaliesMapData);
      }

      return mapData;
    },

    gradientColor() {
      let step = 0;

      switch (this.pipePartsStore.selectedGradientMode) {
        case "ovality_mean": {
          const minMaxOvalityMean = getMinMaxValue(
            this.pipePartsStore.filteredPipeParts,
            "ovality_mean"
          );
          step = (minMaxOvalityMean[1] - minMaxOvalityMean[0]) / 2;
          return [
            minMaxOvalityMean[0],
            gradientColorArr[0],
            Math.round((minMaxOvalityMean[0] + step) * 100) / 100,
            gradientColorArr[1],
            minMaxOvalityMean[1],
            gradientColorArr[2],
          ];
        }

        case "ovality_max": {
          const minMaxOvalityMax = getMinMaxValue(
            this.pipePartsStore.filteredPipeParts,
            "ovality_max"
          );
          step = (minMaxOvalityMax[1] - minMaxOvalityMax[0]) / 2;
          return [
            minMaxOvalityMax[0],
            gradientColorArr[0],
            Math.round((minMaxOvalityMax[0] + step) * 100) / 100,
            gradientColorArr[1],
            minMaxOvalityMax[1],
            gradientColorArr[2],
          ];
        }

        case "horizontal_out_of_straight": {
          const minMaxDeformationHor = getMinMaxValue(
            this.pipePartsStore.filteredPipeParts,
            "horizontal_out_of_straight"
          );

          step = (minMaxDeformationHor[1] - minMaxDeformationHor[0]) / 2;
          return [
            minMaxDeformationHor[0],
            gradientColorArr[0],
            Math.round((minMaxDeformationHor[0] + step) * 100) / 100,
            gradientColorArr[1],
            minMaxDeformationHor[1],
            gradientColorArr[2],
          ];
        }

        case "vertical_out_of_straight": {
          const minMaxDeformationVer = getMinMaxValue(
            this.pipePartsStore.filteredPipeParts,
            "vertical_out_of_straight"
          );
          step = (minMaxDeformationVer[1] - minMaxDeformationVer[0]) / 2;
          return [
            minMaxDeformationVer[0],
            gradientColorArr[0],
            Math.round((minMaxDeformationVer[0] + step) * 100) / 100,
            gradientColorArr[1],
            minMaxDeformationVer[1],
            gradientColorArr[2],
          ];
        }

        case "remaining_life_calculation.road_distance": {
          const minRD = Number(
            this.pipePartsStore.minMaxRoadDistance[0].toFixed(2)
          );
          const maxRD = Number(
            this.pipePartsStore.minMaxRoadDistance[1].toFixed(2)
          );
          step = (maxRD - minRD) / 2;
          return [
            `${minRD} ${this.$units.getAbbr("m")}`,
            gradientColorArr[0],
            `${Math.round((minRD + step) * 100) / 100} ${this.$units.getAbbr(
              "m"
            )}`,
            gradientColorArr[1],
            `${maxRD} ${this.$units.getAbbr("m")}`,
            gradientColorArr[2],
          ];
        }

        case "remaining_life_calculation.degradation_rate":
          step =
            (this.pipePartsStore.minMaxDegradationRate[1] -
              this.pipePartsStore.minMaxDegradationRate[0]) /
            2;
          return [
            this.pipePartsStore.minMaxDegradationRate[0],
            gradientColorArr[0],
            this.pipePartsStore.minMaxDegradationRate[0] + step,
            gradientColorArr[1],
            this.pipePartsStore.minMaxDegradationRate[1],
            gradientColorArr[2],
          ];

        case "remaining_life_calculation.ground_water_level": {
          const minGWL = Number(
            this.pipePartsStore.minMaxGroundWaterLevel[0].toFixed(2)
          );
          const maxGWL = Number(
            this.pipePartsStore.minMaxGroundWaterLevel[1].toFixed(2)
          );
          step = (maxGWL - minGWL) / 2;
          return [
            `${minGWL} ${this.$units.getAbbr("m")}`,
            gradientColorArr[0],
            `${Math.round((minGWL + step) * 100) / 100} ${this.$units.getAbbr(
              "m"
            )}`,
            gradientColorArr[1],
            `${maxGWL} ${this.$units.getAbbr("m")}`,
            gradientColorArr[2],
          ];
        }

        case "diameter_class": {
          const minMaxDiameterClass = getMinMaxValue(
            this.pipePartsStore.filteredPipeParts,
            "diameter_class"
          );

          step = (minMaxDiameterClass[1] - minMaxDiameterClass[0]) / 2;
          const mid = Math.round(minMaxDiameterClass[0] + step);
          if (minMaxDiameterClass[0] === minMaxDiameterClass[1]) {
            return [
              minMaxDiameterClass[0],
              gradientColorArr[0],
              mid + 1,
              gradientColorArr[1],
              minMaxDiameterClass[1] + 2,
              gradientColorArr[2],
            ];
          }
          return [
            minMaxDiameterClass[0],
            gradientColorArr[0],
            mid,
            gradientColorArr[1],
            minMaxDiameterClass[1],
            gradientColorArr[2],
          ];
        }

        case "wall_thickness_original": {
          const minMaxWTOriginal = getMinMaxValue(
            this.pipePartsStore.filteredPipeParts,
            "wall_thickness_original"
          );

          step = (minMaxWTOriginal[1] - minMaxWTOriginal[0]) / 2;
          return [
            minMaxWTOriginal[0],
            gradientColorArr[0],
            Math.round((minMaxWTOriginal[0] + step) * 100) / 100,
            gradientColorArr[1],
            minMaxWTOriginal[1],
            gradientColorArr[2],
          ];
        }

        default: {
          const minMaxWallThickness = getMinMaxWallThickness(
            this.pipePartsStore.filteredPipeParts
          );

          let min = 0;
          let max = 0;

          if (
            minMaxWallThickness &&
            minMaxWallThickness.hasOwnProperty(
              this.pipePartsStore.selectedGradientMode
            )
          ) {
            min =
              minMaxWallThickness[this.pipePartsStore.selectedGradientMode][0];
            max =
              minMaxWallThickness[this.pipePartsStore.selectedGradientMode][1];
          }
          step = (max - min) / 2;
          return [
            min,
            gradientColorArr[0],
            Math.round((min + step) * 100) / 100,
            gradientColorArr[1],
            max,
            gradientColorArr[2],
          ];
        }
      }
    },

    gradientOptions() {
      const hasRemainingLife = this.pipeParts.some(
        ({ remaining_life_calculation }) => !!remaining_life_calculation
      );

      const options = getPipePartsOptions(
        hasRemainingLife,
        this.$t,
        this.$units
      );

      const checkedOptions = options.map((option) => ({
        ...option,
        disabled: this.pipeParts
          ? this.checkDisabledColorMode(option.value)
          : false,
      }));

      return checkedOptions;
    },
  },

  methods: {
    checkDisabledColorMode(mode) {
      if (!mode) return true;

      if (mode === "wall_thickness_original") {
        const disable = !this.pipeParts.some(
          (pipePart) => !!pipePart["wall_thickness_original"]
        );
        return disable;
      }

      const keys = mode.split(".");
      if (keys.length === 1) {
        const hasValues = !!this.pipeParts.find(
          (pipePart) =>
            pipePart[keys[0]] !== null && pipePart[keys[0]] !== undefined
        );

        return !hasValues;
      } else {
        const hasValues = !!this.pipeParts.find(
          (pipePart) =>
            pipePart[keys[0]][keys[1]] !== null &&
            pipePart[keys[0]][keys[1]] !== undefined
        );
        return !hasValues;
      }
    },
  },

  watch: {
    "pipePartsStore.selectedGradientMode"() {
      this.pipePartsStore.resetPlotlyFilter();
    },
    "pipelinesStore.selectedInspectionId": {
      handler(id) {
        if (id) {
          this.anomaliesStore.getAnomalies();
          this.pipePartsStore.initPipeParts();
        }
      },
      immediate: true,
    },
  },
};
</script>
