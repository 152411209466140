<template>
  <BaseWidget :title="title" v-bind="$attrs">
    <PipePartsRadar />
  </BaseWidget>
</template>

<script>
import { BaseWidget } from "@/components/map-componets/widgets";
import { PipePartsRadar } from "@/pages/pipe-parts/components";

export default {
  name: "PipePartsRadarWidget",

  components: {
    BaseWidget,
    PipePartsRadar,
  },

  data() {
    return {
      title: this.$t("pipe_parts.widgets.radar.maximize_title"),
    };
  },
};
</script>
